@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(240, 253, 244);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #a8dadc;
}

.swiper-pagination-progressbar {
  height: 8px !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #a8dadc !important;
  height: 8px !important;
}